<script setup>
    import { ref, onMounted, onUnmounted, watch } from 'vue';
    import Swiper from 'swiper';
    import 'swiper/swiper-bundle.css';
    import Card from './card.vue';

    const isMobile = ref(window.innerWidth <= 767);

    const handleResize = () => {
        isMobile.value = window.innerWidth <= 767;
    };

    onMounted(() => {
        window.addEventListener('resize', handleResize);
        new Swiper('.swiper-container', {
            slidesPerView: 1.3,
            spaceBetween: 16,
            centeredSlides: true,
            grabCursor: true,
        });
    });

    onUnmounted(() => {
        window.removeEventListener('resize', handleResize);
    });
</script>

<template>
    <div
        v-show="isMobile"
        class="swiper-container"
    >
        <div class="swiper-wrapper">
            <div
                :class="`swiper-slide slide-${id}`"
                v-for="id in [1, 2]"
                :key="id"
            >
                <Card :numId="id" />
            </div>
        </div>
    </div>
    <div
        v-show="!isMobile"
        class="card-container container"
    >
        <div
            :class="`slide-${id}`"
            v-for="id in [1, 2]"
            :key="id"
        >
            <Card :numId="id" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '@/styles/variables.scss';
    @import '@/styles/mixins.scss';
    @import '@/styles/functions.scss';

    .swiper-container {
        overflow: hidden;
    }

    .swiper-slide {
        padding: 32px 26px 44px 30px;

        &:first-child {
            margin-left: px-to-vw(-32px, 430px);
        }
    }

    .card-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 16px;

        @include respond-to('small') {
            padding-bottom: 40px;
        }

        @include respond-to('large') {
            justify-content: space-between;
            margin-top: -118px;
            width: 1300px;
        }

        .slide-1 {
            transform: rotate(-8deg);

            @include respond-to('medium') {
                margin-top: -49px;
                margin-left: 12px;
            }
        }

        .slide-2 {
            transform: rotate(4deg);

            @include respond-to('medium') {
                margin-top: 137px;
                margin-right: -14px;
            }

            @include respond-to('xxxlarge') {
                margin-top: 78px;
                margin-right: -31px;
            }
        }
    }
</style>
