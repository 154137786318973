<script setup>
    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
        numId: {
            type: Number,
            required: true,
        },
    });

    const openModal = (numId) => {
        const str = numId === 1 ? 'fistModal' : 'secondModal';
        document.querySelectorAll(`.${str}`).forEach((el) => {
            el.classList.remove('close');
        });
    };
</script>

<template>
    <div
        class="card-button"
        @click="openModal(numId)"
    >
        {{ title }}
    </div>
</template>

<style lang="scss">
    @import '@/styles/variables.scss';
    @import '@/styles/mixins.scss';
    @import '@/styles/functions.scss';

    .card-button {
        display: flex;
        width: 100%;
        padding: 12px 32px;
        justify-content: center;
        align-items: center;

        border-radius: 100px;
        background: $color-green;

        color: $color-wight;
        text-align: center;

        /* Brazil/Title/Title_Bold - 24 */
        font-family: Roboto;
        font-size: px-to-rem(24px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.48px;

        transition: 0.5s;

        @include respond-to('medium') {
            width: 292px;
            margin: 0 auto;
        }

        &:hover {
            background: $color-green-hover;
            cursor: pointer;
        }

        &:active {
            background: $color-green-active;
        }
    }
</style>
