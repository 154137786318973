import { createI18n } from 'vue-i18n';
import { reactive } from 'vue';

// Импортируйте ваши файлы локализации
import en from '@/locales/en.json';
import ru from '@/locales/ru.json';
import az from '@/locales/az.json';
import br from '@/locales/br.json';
import by from '@/locales/by.json';
import es from '@/locales/es.json';
import ko from '@/locales/ko.json';
import ua from '@/locales/ua.json';
import uz from '@/locales/uz.json';

const messages = {
    en,
    ru,
    az,
    br,
    by,
    es,
    ko,
    ua,
    uz,
};

// Функция для получения предпочитаемого языка из localStorage или браузера
function getSavedLocale() {
    const savedLocale = localStorage.getItem('preferred_locale');
    if (savedLocale && savedLocale in messages) {
        return savedLocale;
    }
    return null;
}

// Определение предпочитаемого языка браузера или использование сохраненного в localStorage
const browserLocale = navigator.language.slice(0, 2).toLowerCase();
const defaultLocale =
    getSavedLocale() || (browserLocale in messages ? browserLocale : 'en');

// Сохранение предпочитаемого языка в localStorage
if (defaultLocale) {
    localStorage.setItem('preferred_locale', defaultLocale);
}

// Создание реактивного свойства для текущей локали
const locale = reactive({
    current: defaultLocale,
});

// Функция для обновления текущей локали и сохранения в localStorage
function changeLocale(newLocale) {
    locale.current = newLocale;
    localStorage.setItem('preferred_locale', newLocale);
}

// Установка локали по умолчанию и fallback локали
const i18n = createI18n({
    legacy: false, // Отключаем легаси-режим
    locale: locale.current,
    fallbackLocale: 'en',
    messages,
});

export { i18n, locale, changeLocale };
