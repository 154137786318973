<script setup>
    import { useI18n } from 'vue-i18n';
    import { useToast } from 'vue-toastification';
    import { buildUrlWithParams } from '@/js/buildUrlWithParams';

    // Преобразуем строку параметров в массив
    const targetDomain = import.meta.env.VITE_TARGET_DOMAIN;
    const paramNames = import.meta.env.VITE_PARAM_NAMES
        ? import.meta.env.VITE_PARAM_NAMES.split(',')
        : [];

    const { t } = useI18n();

    const props = defineProps({
        class: {
            type: String,
            required: true,
        },
    });

    const closeModal = () => {
        document.querySelectorAll(`.${props.class}`).forEach((el) => {
            el.classList.add('close');
        });
    };

    const copyText = () => {
        const text =
            props.class === 'fistModal'
                ? t('fistModal.bonus')
                : t('secondModal.bonus');
        const toast = useToast();

        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(text).then(
                () => {
                    toast.success(t('copiedToClipboard'), {
                        icon: 'success',
                    });
                },
                (err) => {
                    console.error('Could not copy text: ', err);
                    toast.error('Failed to copy text.');
                }
            );
        } else {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            toast.success('Text copied to clipboard!');
        }
    };
</script>

<template>
    <div
        class="modal__bg close"
        :class="props.class"
    ></div>
    <div
        class="modal close"
        :class="props.class"
    >
        <div class="coin coin-1"></div>
        <div class="coin coin-2"></div>
        <div class="coin coin-3"></div>
        <div class="coin coin-4"></div>
        <div class="coin coin-5"></div>
        <div class="coin coin-6"></div>
        <div
            class="coin"
            :class="props.class === 'fistModal' ? 'umbrella' : 'cashBack'"
        ></div>
        <div class="modal__content">
            <div class="modal__wrap">
                <div
                    class="close-btn"
                    @click="closeModal()"
                ></div>
                <div
                    class="modal-title"
                    v-html="
                        props.class === 'fistModal'
                            ? $t('fistModal.title')
                            : $t('secondModal.title')
                    "
                ></div>
                <div class="modal-bonus">
                    <div class="text">
                        {{
                            props.class === 'fistModal'
                                ? $t('fistModal.bonus')
                                : $t('secondModal.bonus')
                        }}
                    </div>
                    <div
                        class="icon-copy"
                        @click="copyText(props.class)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="60"
                            height="60"
                            viewBox="0 0 60 60"
                            fill="none"
                        >
                            <path
                                d="M17.4995 15V7.5C17.4995 6.1193 18.6188 5 19.9995 5H49.9995C51.3802 5 52.4995 6.1193 52.4995 7.5V42.5C52.4995 43.8807 51.3802 45 49.9995 45H42.4995V52.4977C42.4995 53.8797 41.3747 55 39.9825 55H10.0166C8.62647 55 7.5 53.8885 7.5 52.4977L7.5065 17.5022C7.50675 16.1203 8.6316 15 10.0235 15H17.4995ZM12.5061 20L12.5005 50H37.4995V20H12.5061ZM22.4995 15H42.4995V40H47.4995V10H22.4995V15Z"
                                fill="#00A803"
                            />
                        </svg>
                    </div>
                </div>
                <div
                    class="modal-text"
                    v-html="
                        props.class === 'fistModal'
                            ? $t('fistModal.text')
                            : $t('secondModal.text')
                    "
                ></div>
                <a
                    :href="buildUrlWithParams(targetDomain, paramNames)"
                    class="card-button"
                    @click="closeModal()"
                    target="_blank"
                    >{{ $t('modalButton') }}</a
                >
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '@/styles/variables.scss';
    @import '@/styles/mixins.scss';
    @import '@/styles/functions.scss';

    .modal__bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(26, 38, 67, 0.6);
        backdrop-filter: blur(50px);
        z-index: 1;
    }

    .modal {
        position: fixed;
        top: 35px;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        padding: 0 16px;
    }

    .modal__content_wrap {
        position: absolute;
        border-radius: 36px;
        width: 382px;
        height: 478px;
        background: rgba(26, 38, 67, 1);
    }

    .modal__content {
        width: 382px;
        height: 478px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 80px 32px 12px;

        border-radius: 36px;
        background: rgba(255, 255, 255, 0.01);
        box-shadow: 0px 4px 24px 0px rgba(0, 5, 54, 0.5),
            0px 32px 45px -30px rgba(255, 255, 255, 0.5) inset,
            0px 6px 10px -3px #fff inset,
            0px 85px 85px -40px rgba(172, 255, 190, 0.3) inset,
            0px 0px 32px 0px rgba(253, 15, 15, 0.2) inset;
        backdrop-filter: blur(42.5px);

        @include respond-to('medium') {
            width: 840px;
            height: 584px;
            padding: 100px 137px 60px;
        }
    }

    .modal__wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .coin {
        position: fixed;
        top: 50%;
        left: 50%;
    }

    .coin-1 {
        transform: translate(222%, -321%);
        width: 50.106px;
        height: 82.798px;
        background: url('@/img/modal/coins/coin-1.svg') no-repeat;
        background-size: contain;

        @include respond-to('medium') {
            transform: translate(350%, -300%);
            width: 80px;
            height: 132px;
        }

        @include respond-to('large') {
            transform: translate(286%, -296%);
        }
    }

    .coin-2 {
        transform: translate(-660%, -605%);
        z-index: 5;
        width: 31.37px;
        height: 31.294px;
        background: url('@/img/modal/coins/coin-2.svg') no-repeat;
        background-size: contain;

        @include respond-to('medium') {
            transform: translate(-660%, -350%);
            width: 76px;
            height: 76px;
        }

        @include respond-to('large') {
            transform: translate(-652%, -327%);
            width: 77px;
            height: 77px;
        }
    }

    .coin-3 {
        transform: translate(-500%, -630%);
        width: 40px;
        height: 25px;
        z-index: 5;
        background: url('@/img/modal/coins/coin-3.svg') no-repeat;
        background-size: contain;

        @include respond-to('medium') {
            transform: translate(-520%, -330%);
            width: 90px;
            height: 60px;
        }

        @include respond-to('large') {
            transform: translate(-478%, -246%);
            width: 102px;
            height: 71px;
        }
    }

    .coin-4 {
        transform: translate(244%, -155%);
        width: 60px;
        height: 53px;
        z-index: 5;
        background: url('@/img/modal/coins/coin-4.svg') no-repeat;
        background-size: contain;

        @include respond-to('medium') {
            transform: translate(230%, -10%);
            width: 148px;
            height: 132px;
        }

        @include respond-to('large') {
            transform: translate(238%, 6%);
            width: 148px;
            height: 132px;
        }
    }

    .coin-5 {
        transform: translate(-406%, 410%);
        width: 51px;
        height: 36px;
        z-index: -1;
        background: url('@/img/modal/coins/coin-5.svg') no-repeat;
        background-size: contain;

        @include respond-to('medium') {
            transform: translate(-400%, 450%);
            width: 82px;
            height: 58px;
        }

        @include respond-to('large') {
            transform: translate(-338%, 461%);
            width: 82px;
            height: 58px;
        }
    }

    .coin-6 {
        transform: translate(467%, 111%);
        width: 100px;
        height: 105px;
        z-index: -1;
        background: url('@/img/modal/coins/coin-2.svg') no-repeat;
        background-size: contain;

        @include respond-to('medium') {
            transform: translate(467%, 111%);
            width: 100px;
            height: 105px;
        }

        @include respond-to('large') {
            transform: translate(467%, 111%);
            width: 100px;
            height: 105px;
        }
    }

    .umbrella {
        transform: translate(-50%, -178%);
        width: 270px;
        height: 217px;
        z-index: 5;
        background: url('@/img/slides/slide-1-img.svg') no-repeat;
        background-size: contain;

        @include respond-to('large') {
            width: 385px;
            height: 279px;
            transform: translate(-45%, -175%);
        }
    }

    .cashBack {
        transform: translate(-48%, -167%);
        width: 270px;
        height: 217px;
        z-index: 5;
        background: url('@/img/slides/slide-2-img.svg') no-repeat;
        background-size: contain;

        @include respond-to('large') {
            transform: translate(-50%, -137%);
            width: 344px;
            height: 356px;
        }
    }

    .show {
        display: block;
    }

    .close-btn {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 44px;
        height: 44px;
        background: url('@/img/modal/close-btn.svg') no-repeat;
        background-size: contain;

        &:hover {
            cursor: pointer;
        }
    }

    .close {
        display: none;
    }

    .modal-title {
        color: $color-wight;
        text-align: center;
        font-size: px-to-rem(18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;
        //margin-bottom: 22px;

        @include respond-to('medium') {
            font-size: px-to-rem(24px);
            letter-spacing: 0.48px;
            //margin-bottom: 50px;
        }
    }

    .modal-bonus {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        //margin-bottom: 24px;

        @include respond-to('medium') {
            //margin-bottom: 50px;
        }

        .text {
            color: $color-wight;
            font-size: px-to-rem(36px);
            font-style: normal;
            font-weight: 900;
            line-height: 100%; /* 36px */
            letter-spacing: 1.44px;

            @include respond-to('medium') {
                font-size: px-to-rem(68px);
                letter-spacing: 1.36px;
            }
        }

        .icon-copy {
            transition: 0.5s;

            svg {
                padding: 10px;
                width: 72px;
                height: 72px;

                path {
                    fill: $color-green; // Используйте переменную цвета
                    transition: 0.5s;
                }
            }

            &:hover {
                cursor: pointer;
                transform: scale(0.9);

                svg {
                    path {
                        fill: $color-green-hover; // Используйте переменную цвета
                    }
                }
            }
        }
    }

    .modal-text {
        color: rgba-color($color-wight, 0.7);
        text-align: center;
        font-size: px-to-rem(18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.36px;

        @include respond-to('medium') {
            font-size: px-to-rem(24px);
            letter-spacing: 0.48px;
        }
    }

    .modal a.card-button {
        display: inline-block;
        white-space: nowrap;
        font-size: px-to-rem(24px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.48px;
        text-decoration: none;
        width: 358px;
        margin-left: -20px;

        @include respond-to('medium') {
            margin: 0 auto;
            width: auto;
        }
    }
</style>
