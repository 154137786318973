/**
 * Модуль для построения URL-адреса с параметрами из текущей строки запроса.
 * Функция `buildUrlWithParams` позволяет создать URL-адрес на основе указанного домена
 * с сохранением параметров из текущего URL.
 */

/**
 * Создает URL-адрес, добавляя к нему параметры из текущей строки запроса.
 *
 * @param {string} targetDomain - Целевой домен, к которому будут добавлены параметры.
 * @param {string[]} [paramNames=[]] - Массив имен параметров, которые нужно добавить к целевому домену.
 *                                     Если не указано, будут добавлены все параметры из текущего URL.
 * @returns {string} - Сформированный URL с добавленными параметрами.
 *
 * @example
 * // Пример использования:
 * const newUrl = buildUrlWithParams('https://example.com', ['param1', 'param2']);
 * console.log(newUrl); // 'https://example.com?param1=value1&param2=value2'
 */
export function buildUrlWithParams(targetDomain, paramNames = []) {
    const currentUrl = new URL(window.location.href);
    const targetUrl = new URL(targetDomain);

    // Получаем все параметры из текущей строки запроса
    const params = new URLSearchParams(currentUrl.search);

    // Если переданы имена параметров, берем только их
    if (paramNames.length > 0) {
        paramNames.forEach((param) => {
            if (params.has(param)) {
                targetUrl.searchParams.set(param, params.get(param));
            }
        });
    } else {
        // Иначе берем все параметры
        params.forEach((value, key) => {
            targetUrl.searchParams.set(key, value);
        });
    }

    return targetUrl.href;
}
