<script setup>
    import { useI18n } from 'vue-i18n';
    import CardButton from './card-button.vue';

    const props = defineProps({
        numId: {
            type: Number,
            required: true,
        },
    });

    const { t } = useI18n();
</script>

<template>
    <div class="gradient-box">
        <div class="coins"></div>
        <div class="img"></div>
        <div class="card">
            <div class="card-content">
                <div class="part-one">
                    {{
                        numId === 1
                            ? t('cardOne.title.partOne')
                            : t('cardTwo.title.partOne')
                    }}
                </div>
                <div class="part-two">
                    {{
                        numId === 1
                            ? t('cardOne.title.partTwo')
                            : t('cardTwo.title.partTwo')
                    }}
                </div>
            </div>
            <CardButton
                :title="$t('cardButton')"
                :numId="numId"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '@/styles/variables.scss';
    @import '@/styles/mixins.scss';
    @import '@/styles/functions.scss';

    .gradient-box {
        width: 276px;
        height: 400px;
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        $border: 2px;
        color: $color-wight;
        background: rgba-color($color-wight, 0.01);
        background-clip: padding-box; /* !importanté */
        border: solid $border transparent; /* !importanté */
        border-radius: 36px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border; /* !importanté */
            border-radius: inherit; /* !importanté */
            background: linear-gradient(
                50deg,
                $color-wight 0%,
                transparent 20%
            );
        }

        @include respond-to('medium') {
            width: 356px;
            height: 520px;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;
        height: 100%;
        border-radius: 36px;
        background: rgba(255, 255, 255, 0.01);
        box-shadow: 0px 4px 24px 0px rgba(0, 5, 54, 0.5),
            0px 32px 45px -30px rgba(255, 255, 255, 0.5) inset,
            0px 6px 10px -3px #fff inset,
            0px 85px 85px -40px rgba(172, 255, 190, 0.3) inset,
            0px 0px 32px 0px rgba(253, 15, 15, 0.2) inset;
        backdrop-filter: blur(42px);
        padding: 22.5px 8px 12px 8px;

        @include respond-to('medium') {
            padding: 32px 16px 24px 16px;
        }

        @include respond-to('large') {
            padding: 32px 24px 24px;
        }

        .part-one {
            color: var(--White-100, #fff);
            text-align: center;
            text-shadow: 0px 4px 4px rgba(18, 68, 0, 0.5);
            font-size: px-to-rem(58px);
            font-style: normal;
            font-weight: 900;
            line-height: 100%;

            @include respond-to('medium') {
                font-size: px-to-rem(68px);
                letter-spacing: 1.36px;
            }
        }

        .part-two {
            color: var(--White-100, #fff);
            text-align: center;
            text-shadow: 0px 4px 4px rgba(18, 68, 0, 0.5);
            font-size: px-to-rem(18px);
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
            letter-spacing: 0.36px;

            @include respond-to('medium') {
                font-size: px-to-rem(24px);
            }
        }
    }

    .slide-1 {
        .coins {
            position: absolute;
            top: -32px;
            left: -12px;
            z-index: 20;
            width: 300px;
            height: 346px;
            background: url('@/img/slides/slide-1-coins.svg') no-repeat;
            background-size: contain;

            @include respond-to('medium') {
                width: 400px;
                height: 460px;
                left: -22px;
            }
        }

        .img {
            position: absolute;
            top: 116px;
            left: -20px;
            z-index: 19;
            width: 259px;
            height: 208px;
            background: url('@/img/slides/slide-1-img.svg') no-repeat;
            background-size: contain;

            @include respond-to('medium') {
                width: 344px;
                height: 276px;
                left: -24px;
                top: 144px;
            }
        }
    }

    .slide-2 {
        .card-content {
            display: flex;
            flex-direction: column-reverse;
        }

        .img {
            position: absolute;
            top: 91px;
            left: -22px;
            z-index: 19;
            width: 316px;
            height: 253px;
            background: url('@/img/slides/slide-2-img.svg') no-repeat;
            background-size: contain;

            @include respond-to('medium') {
                width: 400px;
                height: 340px;
                left: -32px;
                top: 116px;
            }
        }
    }
</style>
