<script setup>
    import { useI18n } from 'vue-i18n';
    import Header from '@/components/header.vue';
    import CardBlock from '@/components/card-block.vue';
    import modalMain from './components/modal-main.vue';

    const { locale } = useI18n();

    const { t } = useI18n();
</script>

<template>
    <Header />
    <main>
        <div class="container">
            <h1 class="title">{{ $t('mainText.title') }}</h1>
            <p
                class="text"
                :class="`text-${locale}`"
            >
                {{ $t('mainText.text') }}
            </p>
        </div>
        <CardBlock />
    </main>
    <modalMain class="fistModal" />
    <modalMain class="secondModal" />
</template>

<style lang="scss" scoped>
    @import '@/styles/variables.scss';
    @import '@/styles/mixins.scss';
    @import '@/styles/functions.scss';

    h1.title {
        color: $color-light-blue;
        font-size: px-to-vw(36px, 430px);
        font-weight: 900;
        line-height: px-to-vw(36px, 430px);
        letter-spacing: 0.04em;
        text-align: center;
        margin-bottom: 16px;

        @include respond-to('medium') {
            font-size: px-to-rem(68px);
            line-height: px-to-rem(68px);
            font-style: normal;
            font-weight: 900;
            letter-spacing: 1.36px;
            width: px-to-vw(705px, 1020px);
            margin: 0 auto;
            margin-bottom: 16px;
        }

        @include respond-to('xxxlarge') {
            font-size: px-to-rem(68px);
            line-height: px-to-rem(68px);
            font-style: normal;
            font-weight: 900;
            letter-spacing: 1.36px;
            width: 705px;
        }
    }

    p.text {
        color: $color-blue;
        max-width: px-to-vw(300px, 430px);
        margin: 0 auto;
        margin-bottom: 17.6vh;

        text-align: center;
        font-size: px-to-vw(18px, 430px);
        font-style: normal;
        font-weight: 500;
        line-height: px-to-vw(24px, 430px);
        letter-spacing: 0.36px;

        @include respond-to('medium') {
            font-size: px-to-rem(24px);
            font-style: normal;
            font-weight: 500;
            line-height: px-to-rem(32px);
            letter-spacing: 0.48px;
            margin-bottom: 200px;
        }

        @include respond-to('xxxlarge') {
            font-size: px-to-rem(24px);
            line-height: px-to-rem(30px);
            letter-spacing: 0.48px;

            &-by {
                width: 1000px;
            }
        }
    }
</style>
