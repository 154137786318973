import { createApp } from 'vue';
import './style.scss';
import App from './App.vue';
import { i18n } from '@/js/i18n';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const app = createApp(App);

app.use(Toast, {
    position: POSITION.BOTTOM_CENTER,
    timeout: 3000,
    hideProgressBar: true,
    toastClassName: 'custom-toast',
    bodyClassName: 'custom-toast-body',
});

app.use(i18n);
app.mount('#app');
